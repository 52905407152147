import { useSWRConfig } from "swr";

import { organizationUserEndpoints } from "@api/Endpoints";
import deleteData from "@hooks/useDeleteData";
import { putData } from "@hooks/usePutData";
import { useUserContext } from "@hooks/useUserContext";
import { AddUserToOrganizationResponse } from "@users/organizationuser/models/AddUserToOrganizationResponse";
import { InviteOrganizationUser } from "@users/organizationuser/models/InviteOrganizationUser";
import { UpdateUserRole } from "@users/organizationuser/models/UpdateUserRole";
import { useCallback, useMemo } from "react";
import { ApiResponse } from "@hooks/ApiResponse";

export function useInviteOrganizationUser() {
  const { getAccessToken, user } = useUserContext();
  const { mutate } = useSWRConfig();

  const invite = useCallback(async (inviteOrganizationUser: InviteOrganizationUser) : Promise<ApiResponse<AddUserToOrganizationResponse>> => {
      const url = organizationUserEndpoints.addUser();
      const result = await putData<InviteOrganizationUser, AddUserToOrganizationResponse>(getAccessToken, user, url, inviteOrganizationUser);

      const getUrl = organizationUserEndpoints.organizationUsers();
      await mutate(getUrl);

      return result;
    },
  [getAccessToken, user, mutate]
);

return useMemo(
  () => ({
    invite
  }),
  [invite]
);
}

export function useUpdateOrganizationUser(userId: string) {
  const { getAccessToken, user } = useUserContext();
  const { mutate } = useSWRConfig();

  const update = async (updateUserRole: UpdateUserRole) => {
    const url = organizationUserEndpoints.updateUserRole(userId);
    const result = await putData<UpdateUserRole, string>(
      getAccessToken,
      user,
      url,
      updateUserRole
    );

    const getUrl = organizationUserEndpoints.organizationUsers();
    await mutate(getUrl);

    return result;
  };

  return { update };
}

export function useRemoveOrganizationUsers() {
  const { getAccessToken } = useUserContext();
  const { mutate } = useSWRConfig();

  const deleteUser = async (userId: string) => {
    const url = organizationUserEndpoints.removeUser(userId);
    const result = await deleteData(getAccessToken, url);

    const getUrl = organizationUserEndpoints.organizationUsers();
    await mutate(getUrl);

    return result;
  };

  return { deleteUser };
}
