import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import {
  IconButton,
  LoadingIndicator,
  Tooltip,
  useAlert,
  useDialog,
} from "@destination/components";

import { useGetDefaultOrganization } from "@account/services/use-get-default-organization";
import { useSetDefaultOrganization } from "@account/services/use-set-default-organization";
import { Organization } from "@hooks/useOrganizationContext/models/Organization";
import { ReactComponent as ErrorIcon } from "@icons/error.svg";
import { ReactComponent as StarIcon } from "@icons/star.svg";
import { ReactComponent as StarStrokeIcon } from "@icons/starStroke.svg";

interface Props {
  organization: Organization;
}

const DefaultOrganizationToggleButton: FunctionComponent<Props> = ({
  organization,
}) => {
  const { update: setDefaultOrganization } = useSetDefaultOrganization();
  const { defaultOrganizationId, isLoading, error } = useGetDefaultOrganization();

  const { t } = useTranslation(undefined, { keyPrefix: "organization" });
  const { t: tCommon } = useTranslation();
  const { trigger } = useDialog();
  const { notify } = useAlert();

  function onSetDefaultOrganization(organization: Organization) {
    trigger({
      variant: "warning",
      header: t("default.confirm.title"),
      content: t("default.confirm.message", {
        organization: organization.name,
      }),
      confirmButton: tCommon("button.confirm.label"),
      onConfirm: async () => {
        const { isSuccess } = await setDefaultOrganization(organization.id);
        if (!isSuccess) {
          notify({
            header: t("default.error.message", {
              organization: organization.name,
            }),
            variant: "error",
            isDismissible: true,
            timeout: 6000,
            "data-testid": "set-default-organization-error",
          });
        }
      },
      cancelButton: tCommon("button.cancel.label"),
      onCancel: () => {
        /* Do nothing */
      },
      "data-testid": "set-default-organization-confirmation",
    });
  }
  if (isLoading) return <LoadingIndicator children={undefined} />;

  if (error) {
    return (
      <Tooltip message={t("load.error.message")} placement="bottom-left">
        <div data-testid="error-icon">
          <ErrorIcon className="max-w-none m-3 h-3 sm:h-4" />
        </div>
      </Tooltip>
    );
  }

  return (
    <>
      {defaultOrganizationId === organization.id && (
        <StarIcon data-testid="star-icon" className="max-w-none m-3 mt-1 h-3 sm:h-4" />
      )}
      {defaultOrganizationId !== organization.id && (
        <div className="group/edit invisible group-hover/item:visible">
          <Tooltip
            message={t("default.tooltip", {
              organization: organization.name,
            })}
            placement="bottom-left"
            data-testid="set-default-tooltip"
          >
            <IconButton
              data-testid={`set-default-${organization.id}`}
              onClick={() => {
                onSetDefaultOrganization(organization);
              }}
            >
              <StarStrokeIcon className="h-3 sm:h-4 m-1" />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default DefaultOrganizationToggleButton;
