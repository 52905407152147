import { IAlert } from "@destination/components/dist/components/Alert/Alert";
import { useAlert } from "@destination/components";
import { useMemo } from "react";

interface IShowAlert {
    header: IAlert['header'];
    "data-testid"?: IAlert['data-testid'];
}

const useAlerts = () => {
    const { notify } = useAlert();

    const value = useMemo(() => ({
        showErrorAlert: (alert: IShowAlert) => {
            notify({
                header: alert.header,
                variant: 'error',
                "data-testid": alert['data-testid'],
                isDismissible: true,
                isUnique: false,
                isDismissMandatory: true
            });
        },
        showSuccessAlert: (alert: IShowAlert) => {
            notify({
                header: alert.header,
                variant: 'success',
                timeout: 8000,
                "data-testid": alert['data-testid'],
                isDismissible: true,
                isUnique: false
            });
        }
    }), [notify]);

    return value;
};

export default useAlerts;
