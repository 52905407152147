import axios from "axios";
import { handleError } from "@utils/axios-utils";
import { User } from "./useUserContext/models/User";
import { ApiResponse } from "./ApiResponse";

export const putData = async <TRequest = unknown, TResponse = unknown>(
getAccessToken: () => Promise<string>, user: User, url: string, data: TRequest) : Promise<ApiResponse<TResponse>> => {
  try {
    const accessToken = await getAccessToken();

    const request = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Accept-Language': user.language
      },
    };

    const response = await axios.put<TResponse>(url, data, request);
    
    return { statusCode: response.status, data: response.data, isSuccess: true };
  } catch ( error) {
      return handleError<TResponse>(error as Error);
  }
};