import { ApiResponse } from "@hooks/ApiResponse";
import axios from 'axios';

export interface ProblemDetails {
  readonly type: string;
  readonly title: string;
  readonly status: number;
  readonly detail: string;
  readonly errors?: Record<string, string[]>;
  readonly [key: string]: unknown;
}

export function handleError<TResponse>(error: Error) : ApiResponse<TResponse>{
  if (axios.isAxiosError(error)) {
    const status = error.response?.status ?? 500;

    if (status >= 500){
        return {isSuccess: false, statusCode: error.status ?? 500};
    }

    const problemDetails = error?.response?.data as ProblemDetails;
    if (problemDetails) {
      if (problemDetails.errors) {
        return {isSuccess: false, statusCode: status, errorMessage: problemDetails.title, validationErrors: problemDetails.errors};
      }

      return {isSuccess: false, statusCode: status, errorMessage: problemDetails.detail};
    }
  }
  
  return {isSuccess: false, statusCode: 500};
}