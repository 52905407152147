import { useCallback, useMemo } from "react";

import useSWR, { mutate, useSWRConfig } from "swr";

import { applicationEndpoints } from "@api/Endpoints";
import deleteData from "@hooks/useDeleteData";
import { putData } from "@hooks/usePutData";
import { useUserContext } from "@hooks/useUserContext";
import { AssociatedApplicationRoles } from "@users/applicationuser/models/ApplicationRole";
import { fetcher } from "@utils/swrUtils";
import { UserApplicationsResponse } from "../models/UserApplicationsResponse";

export function useGetApplicationRoles(applicationId: string) {
  const { getAccessToken } = useUserContext();
  const url = applicationEndpoints.applicationRoles(applicationId);

  const { data, error, isLoading } = useSWR<AssociatedApplicationRoles, Error>(
    url,
    (url: string) => fetcher(url, getAccessToken)
  );

  return useMemo(
    () => ({
      applicationRoles: data?.applicationRoles ?? [],
      limit: data?.numberOfRoles ?? 0,
      allowUpdate: data?.updateUserAllowed ?? false,
      isLoading,
      error,
    }),
    [data, isLoading, error]
  );
}

export function useInviteApplicationUser() {
  const { getAccessToken, user } = useUserContext();
  const { mutate } = useSWRConfig();

  const invite = useCallback(
    async (
      applicationId: string,
      userId: string,
      applicationRoles: string[]
    ) => {
      const url = applicationEndpoints.updateOrDeleteApplicationUsers(
        applicationId,
        userId
      );

      const result = await putData<{ applicationRoles: string[] }>(
        getAccessToken,
        user,
        url,
        {
          applicationRoles: applicationRoles,
        }
      );
      await mutate(applicationEndpoints.applicationUsers(applicationId));

      await mutate(applicationEndpoints.userApplications(userId));
      return result;
    },
    [getAccessToken, user, mutate]
  );

  return useMemo(() => ({ invite }), [invite]);
}

export function useUserApplications(userId: string) {
  const { getAccessToken } = useUserContext();
  const url = applicationEndpoints.userApplications(userId);

  const { data, error, isLoading } = useSWR<UserApplicationsResponse, Error>(
    url,
    (url: string) => fetcher(url, getAccessToken)
  );

  return useMemo(
    () => ({ userApplications: data?.applications, isLoading, error }),
    [data, isLoading, error]
  );
}

export function useRemoveUserApplication() {
  const { getAccessToken } = useUserContext();

  const deleteApp = useCallback(
    async (applicationId: string, userId: string) => {
      const url = applicationEndpoints.updateOrDeleteApplicationUsers(
        applicationId,
        userId
      );
      const result = await deleteData(getAccessToken, url);
      await mutate(applicationEndpoints.applicationUsers(applicationId));
      return result;
    },
    [getAccessToken]
  );

  return useMemo(() => ({ deleteApp }), [deleteApp]);
}
